import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionCharacters } from '../Assets/characters.svg';
import "./characters.css";
import Footer from '../Sections/footer';

function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

function insertTextIntoSvg(textContent, maxWidthVW) {
    const textElement = document.getElementById('bio');
    if (!textElement) {
      console.error('Element with id "bio" not found.');
      return;
    }

    // Create a temporary SVG element to measure text
    const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const tempText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    tempSvg.appendChild(tempText);
    document.body.appendChild(tempSvg);

    // Set attributes for measurement
    tempText.setAttribute('x', '0');
    tempText.setAttribute('y', '0');
    tempText.setAttribute('font-size', '16'); // Set font size as needed

    // Split text into lines based on viewport width
    const maxWidthPx = window.innerWidth * maxWidthVW / 100; // Max width in pixels
    let lines = [];
    let currentLine = '';
    let tempTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');

    textContent.split(' ').forEach(word => {
      tempTspan.textContent = currentLine + word + ' ';
      tempText.appendChild(tempTspan);
      const width = tempTspan.getBBox().width;

      if (width > maxWidthPx) {
        lines.push(currentLine.trim());
        currentLine = word + ' ';
        tempTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      } else {
        currentLine += word + ' ';
      }
    });

    // Push the last line
    lines.push(currentLine.trim());

    // Clean up temporary elements
    document.body.removeChild(tempSvg);

    // Clear existing content and append new tspans
    textElement.innerHTML = '';
    lines.forEach((line, index) => {
      const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.setAttribute('x', '0'); // Center text horizontally within the <text> element
      if (index > 0) {
        tspan.setAttribute('dy', '1.2em');
      }
      tspan.textContent = line;
      textElement.appendChild(tspan);
    });

    // Set the x attribute of the <text> element to center text within SVG
    textElement.setAttribute('x', '50%');
  }

export default function Characters({section_id}) {
    var images = [];
    var startingIndex = 0;
    var isUseEffectcalled = false;
    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get image order
            fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch(`https://thestoryloft.in/api/images?section_id=${section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            images = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);

                            if (images[startingIndex]) {
                                setImageHref("characterPic", images[startingIndex].image_url);
                                document.getElementById("firstName").innerHTML = images[startingIndex].image_name.split(" ")[0];
                                document.getElementById("lastName").innerHTML = images[startingIndex].image_name.split(" ")[1];
                                document.getElementById("designation0").innerHTML = images[startingIndex].designation.split("AND")[0]+ "AND";
                                if(images[startingIndex].designation.split("AND")[1])
                                document.getElementById("designation1").innerHTML = images[startingIndex].designation.split("AND")[1].trim();
                                insertTextIntoSvg(images[startingIndex].image_description,19);
                                console.log(images[startingIndex],"images[startingIndex]")
                            }
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
        }
    }, []);

    useEffect(() => {
        const leftArrow = document.getElementById('character_moveleft');
        const rightArrow = document.getElementById('character_moveright');
        if(leftArrow && startingIndex == 0) leftArrow.style.cursor = "no-drop";
        if (rightArrow && startingIndex+1  == images.length ) rightArrow.style.cursor = "no-drop";
        if (leftArrow) {
            leftArrow.addEventListener('click', () => {
                if (startingIndex > 0) {
                    startingIndex--;
                    if (startingIndex == 0) {
                        leftArrow.style.cursor = "no-drop";
                        rightArrow.style.cursor = "pointer";
                    }
                    setImageHref("characterPic", images[startingIndex].image_url);
                    document.getElementById("firstName").innerHTML = images[startingIndex].image_name.split(" ")[0];
                    document.getElementById("lastName").innerHTML = images[startingIndex].image_name.split(" ")[1];
                    document.getElementById("designation0").innerHTML = images[startingIndex].designation.split("AND")[0]+ "AND";
                    if(images[startingIndex].designation.split("AND")[1])
                    document.getElementById("designation1").innerHTML = images[startingIndex].designation.split("AND")[1].trim();
                    insertTextIntoSvg(images[startingIndex].image_description,19);
                }
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', async () => {
                if (startingIndex  < images.length-1) {
                    startingIndex++;
                    if (startingIndex+1  == images.length) {
                        rightArrow.style.cursor = "no-drop";
                        leftArrow.style.cursor = "pointer";
                    }
                    setImageHref("characterPic", images[startingIndex].image_url);
                    document.getElementById("firstName").innerHTML = images[startingIndex].image_name.split(" ")[0];
                    document.getElementById("lastName").innerHTML = images[startingIndex].image_name.split(" ")[1];
                    document.getElementById("designation0").innerHTML = images[startingIndex].designation.split("AND")[0]+ "AND";
                    if(images[startingIndex].designation.split("AND")[1])
                    document.getElementById("designation1").innerHTML = images[startingIndex].designation.split("AND")[1].trim();
                    insertTextIntoSvg(images[startingIndex].image_description,19);
                }
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (leftArrow) {
                leftArrow.removeEventListener('click', () => {
                });
            }
            if (rightArrow) {
                rightArrow.removeEventListener('click', () => {
                });
            }
        };
    }, []);
    return (
        <Grid className='character-svg-container'>
            <SectionCharacters className='character-responsive-svg' />
            <Footer marginTop={"-300px"}/>
        </Grid>
    );
}
