import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionBrandCommunications } from '../Assets/brandCommunications.svg';
import "./brandCommunications.css";
import Footer from '../Sections/footer';
function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

function insertTextIntoSvg(textContent, maxWidthVW,id,fontSize) {
    const textElement = document.getElementById(id);
    if (!textElement) {
      console.error('Element with id "bcContent" not found.');
      return;
    }

    // Create a temporary SVG element to measure text
    const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const tempText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    tempSvg.appendChild(tempText);
    document.body.appendChild(tempSvg);

    // Set attributes for measurement
    tempText.setAttribute('x', '0');
    tempText.setAttribute('y', '0');
    tempText.setAttribute('font-size', fontSize?fontSize:'16'); // Set font size as needed

    // Split text into lines based on viewport width
    const maxWidthPx = window.innerWidth * maxWidthVW / 100; // Max width in pixels
    let lines = [];
    let currentLine = '';
    let tempTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');

    textContent.split(' ').forEach(word => {
      tempTspan.textContent = currentLine + word + ' ';
      tempText.appendChild(tempTspan);
      const width = tempTspan.getBBox().width;

      if (width > maxWidthPx) {
        lines.push(currentLine.trim());
        currentLine = word + ' ';
        tempTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      } else {
        currentLine += word + ' ';
      }
    });

    // Push the last line
    lines.push(currentLine.trim());

    // Clean up temporary elements
    document.body.removeChild(tempSvg);

    // Clear existing content and append new tspans
    textElement.innerHTML = '';
    lines.forEach((line, index) => {
      const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.setAttribute('x', '0'); // Center text horizontally within the <text> element
      if (index > 0) {
        tspan.setAttribute('dy', '1.2em');
      }
      tspan.textContent = line;
      textElement.appendChild(tspan);
    });

    // Set the x attribute of the <text> element to center text within SVG
    textElement.setAttribute('x', '50%');
  }
export default function BrandCommunications({ section_id }) {
    var images = [];
    var startingIndex = 0;
    var isUseEffectcalled = false;
    var loopLogo = null;

    const updateImage = (images) =>{
      for(let i=0 ; i<images.length; i++){
        if(i<6){
          if(i==2){
             setImageHref("bdImage"+(i+1), images[i].image_url);
             document.getElementById("bcdescriptionTitle").innerHTML= images[i].image_description_title;
             document.getElementById("bcdescription").innerHTML= images[i].image_description;
            //  insertTextIntoSvg(images[i].image_description, 12,"bcdescription",'10'); // 50vw as max width
          }
          else setImageHref("bdImage"+(i+1), images[i].mini_image_url);
        }                             
      }
    }
    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get image order
            fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch(`https://thestoryloft.in/api/images?section_id=${section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            images = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);
                            console.log(images,"images")
                            updateImage(images);                            
                            loopLogo = setInterval(() => {
                              let firstImage = images.shift();
                              images.push(firstImage);
                              updateImage(images); 
                            }, 4000);
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
                fetch("https://thestoryloft.in/api/content/29", requestOptions)
                .then((response) => response.json())
                .then(resp=>{
                    console.log(resp,"resp");
                    insertTextIntoSvg(resp[0].content, 31,"bcContent"); // 50vw as max width
                })
                .catch((error) => console.error(error))
        }
        return ()=>{
          clearInterval(loopLogo);
        }
    }, []);

    return (
        <Grid className='bc-outline'>
            <SectionBrandCommunications />
            <Footer marginTop={"-350px"}/>
        </Grid>
    );
}
