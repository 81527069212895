import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionPortfolio } from '../Assets/portfolio4.svg';
import "./portfolio.css";
import Footer from '../Sections/footer';
function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

export default function Portfolio() {
    var images = [];
    var startingIndex = 0;
    var isUseEffectcalled = false;
    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get image order
            fetch("https://thestoryloft.in/api/orders/20", requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch("https://thestoryloft.in/api/images?section_id=20&refresh=" + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            images = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);

                            for (let i = 0; i < (images.length < 4 ? images.length : 4); i++) {
                                if (images[i]) {
                                    if (i <= 2) {
                                        setImageHref("portfolio_image" + (i + 1), images[i].image_url);
                                    }
                                }
                            }
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
        }
    }, []);
    useEffect(() => {
        const leftArrow = document.getElementById('portfolio_moveleft');
        const rightArrow = document.getElementById('portfolio_moveright');
        if(leftArrow && startingIndex == 0) leftArrow.style.cursor = "no-drop";
        if (rightArrow && images.length > 4) rightArrow.style.cursor = "no-drop";
        if (leftArrow) {
            leftArrow.addEventListener('click', () => {
                if (startingIndex > 0) {
                    startingIndex--;
                    if (startingIndex == 0) {
                        leftArrow.style.cursor = "no-drop";
                        rightArrow.style.cursor = "pointer";
                    }
                    setImageHref("portfolio_image1", images[startingIndex]["image_url"]);
                    setImageHref("portfolio_image2", images[startingIndex + 1]["image_url"]);
                    setImageHref("portfolio_image3", images[startingIndex + 2]["image_url"]);
                }
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', async () => {
                if (startingIndex + 3 < images.length) {
                    startingIndex++;
                    if (startingIndex + 3 == images.length) {
                        rightArrow.style.cursor = "no-drop";
                        leftArrow.style.cursor = "pointer";
                    }

                    if (images[startingIndex + 2]) {
                        setImageHref("portfolio_image1", images[startingIndex]["image_url"]);
                        setImageHref("portfolio_image2", images[startingIndex + 1]["image_url"]);
                        setImageHref("portfolio_image3", images[startingIndex + 2]["image_url"]);
                    }
                }


            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (leftArrow) {
                leftArrow.removeEventListener('click', () => {
                });
            }
            if (rightArrow) {
                rightArrow.removeEventListener('click', () => {
                });
            }
        };
    }, []);

    return (
        <Grid className='svg-container'>
            <SectionPortfolio className='responsive-svg' />
            <Footer/>
        </Grid>
    );
}
