import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionNewsAndUpdates } from '../Assets/NewsAndUpdates.svg';
import "./newsAndUpdates.css";
import moment from 'moment';
import Footer from '../Sections/footer';
function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

const hideNewIndication = () =>{
    document.getElementById("New_bubble").style = "display:none";
    document.getElementById("news_border_line").style = "display:none";
    document.getElementById("news_date_text").style = "display:none";
    document.getElementById("news_date_newText").style = "display:none";
}

const ShowNewIndication = (date) =>{
    document.getElementById("New_bubble").style = "display:block";
    document.getElementById("news_border_line").style = "display:block";
    document.getElementById("news_date_text").style = "display:block";
    document.getElementById("news_date_newText").style = "display:block";
    document.getElementById("news_date_text").innerHTML = date;
}

export default function NewsAndUpdates({ section_id}) {
    var images = [];
    var startingIndex = 0;
    var isUseEffectcalled = false;    
    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get image order
            fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch(`https://thestoryloft.in/api/images?section_id=${section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            console.log(result);
                            images = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);

                            if (images[startingIndex]) {
                                setImageHref("news_image", images[startingIndex].image_url);
                                document.getElementById("news_text").innerHTML = images[startingIndex].image_description;
                                if(moment(images[startingIndex].image_date?images[startingIndex].image_date:"").format("MMM") == moment().format("MMM")){
                                    ShowNewIndication(moment(images[startingIndex].image_date?images[startingIndex].image_date:"").format("DD/MM/YYYY"))
                                }
                                else{
                                    hideNewIndication()
                                }
                            }
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
        }
    }, []);
    useEffect(() => {
        const leftArrow = document.getElementById('news_moveleft');
        const rightArrow = document.getElementById('news_moveright');
        if(leftArrow && startingIndex == 0) leftArrow.style.cursor = "no-drop";
        if (rightArrow && startingIndex+1  == images.length ) rightArrow.style.cursor = "no-drop";
        if (leftArrow) {
            leftArrow.addEventListener('click', () => {
                if (startingIndex > 0) {
                    startingIndex--;
                    if (startingIndex == 0) {
                        leftArrow.style.cursor = "no-drop";
                        rightArrow.style.cursor = "pointer";
                    }
                    setImageHref("news_image", images[startingIndex].image_url);
                    document.getElementById("news_text").innerHTML = images[startingIndex].image_description;
                    if(moment(images[startingIndex].image_date?images[startingIndex].image_date:"").format("MMM") == moment().format("MMM")){
                        ShowNewIndication(moment(images[startingIndex].image_date?images[startingIndex].image_date:"").format("DD/MM/YYYY"))
                    }
                    else{
                        hideNewIndication()
                    }
                }
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener('click', async () => {
                if (startingIndex  < images.length-1) {
                    startingIndex++;
                    if (startingIndex+1  == images.length) {
                        rightArrow.style.cursor = "no-drop";
                        leftArrow.style.cursor = "pointer";
                    }
                    setImageHref("news_image", images[startingIndex].image_url);
                    document.getElementById("news_text").innerHTML = images[startingIndex].image_description;
                    if(moment(images[startingIndex].image_date?images[startingIndex].image_date:"").format("MMM") == moment().format("MMM")){
                        ShowNewIndication(moment(images[startingIndex].image_date?images[startingIndex].image_date:"").format("DD/MM/YYYY"))
                    }
                    else{
                        hideNewIndication()
                    }
                }
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (leftArrow) {
                leftArrow.removeEventListener('click', () => {
                });
            }
            if (rightArrow) {
                rightArrow.removeEventListener('click', () => {
                });
            }
        };
    }, []);
    return (
        <Grid className='news-outline'>
            <SectionNewsAndUpdates />
            <Footer></Footer>
        </Grid>
    );
}
