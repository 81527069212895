import Grid from '@mui/material/Grid';
import { ReactComponent as Section4SVG } from '../Assets/Section4.svg';
import "./Section4.css";
import { useEffect } from 'react';
export default function Section4() {

    useEffect(() => {
        // Select the element with id "section4"
        const targetElement = document.getElementById('s4_wind3');

        // Create an Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        // Element is in the viewport
                        document.querySelector("#s4Contact").classList.add("s4show");
                        document.querySelector("#s4Contact").classList.remove("s4hide");

                        document.querySelector("#s4Text").classList.add("s4show");
                        document.querySelector("#s4Text").classList.remove("s4hide");

                        document.querySelector("#s4Bar").classList.add("s4show");
                        document.querySelector("#s4Bar").classList.remove("s4hide");

                        document.querySelector("#s4BarTop").classList.add("s4hide");
                        document.querySelector("#s4BarTop").classList.remove("s4show");
                    }, 100);

                } else {
                    // Element is out of the viewport
                    document.querySelector("#s4Contact").classList.add("s4hide");
                    document.querySelector("#s4Contact").classList.remove("s4show");

                    document.querySelector("#s4Text").classList.add("s4hide");
                    document.querySelector("#s4Text").classList.remove("s4show");

                    document.querySelector("#s4Bar").classList.add("s4hide");
                    document.querySelector("#s4Bar").classList.remove("s4show");

                    document.querySelector("#s4BarTop").classList.add("s4show");
                    document.querySelector("#s4BarTop").classList.remove("s4hide");
                }
            });
        }, {
            threshold: 0.9 // Trigger when 90% of the element is visible
        });

        // Observe the target element
        observer.observe(targetElement);

    })

    return (
        <Grid className='section4outline' id="section4outline" style={{paddingTop:"50px"}}>
            <Section4SVG />
        </Grid>
    )
}