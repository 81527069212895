import Grid from '@mui/material/Grid';
import { ReactComponent as Section2SVG } from '../Assets/Section2.svg';
import Typography from '@mui/material/Typography';

import "./Section2.css";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Section2() {
    const navigate = useNavigate();
    useEffect(() => {
        const learningAndDevelopment = document.getElementById('learningAndDevelopment');
        if (learningAndDevelopment) {
            learningAndDevelopment.addEventListener('click', () => {
                navigate('/learningAndDevelopment')
            });
        }

        const performances = document.getElementById('performances');
        if (performances) {
            performances.addEventListener('click', () => {
                navigate('/performanceAndFineArt')
            });
        }

        const brandCommunication = document.getElementById('brandCommunication');
        if (brandCommunication) {
            brandCommunication.addEventListener('click', () => {
                navigate('/brandCommunications')
            });
        }

        const brandCommunicationText = document.getElementById('bcSubHeadingDisplay1');
        if (brandCommunicationText) {
            brandCommunicationText.addEventListener('mouseenter', () => {
                document.querySelector("#bcSubHeadingBarDisplay1").classList.add("s2hide");
                document.querySelector("#bcSubHeadingBarDisplay2").classList.remove("s2hide");
                document.querySelector("#bcSubHeadingDisplay1").classList.add("s2hide");
                document.querySelector("#bcSubHeadingDisplay2").classList.remove("s2hide");
            });
        }

        const brandCommunicationFullText = document.getElementById('bcSubHeadingDisplay2');
        if (brandCommunicationFullText) {
            brandCommunicationFullText.addEventListener('mouseleave', () => {
                document.querySelector("#bcSubHeadingBarDisplay1").classList.remove("s2hide");
                document.querySelector("#bcSubHeadingBarDisplay2").classList.add("s2hide");
                document.querySelector("#bcSubHeadingDisplay1").classList.remove("s2hide");
                document.querySelector("#bcSubHeadingDisplay2").classList.add("s2hide");
            });
        }

        const learningAndDevelopmentText = document.getElementById('ldSubHeadingDisplay1');
        if (learningAndDevelopmentText) {
            learningAndDevelopmentText.addEventListener('mouseenter', () => {
                document.querySelector("#ldSubHeadingBarDisplay1").classList.add("s2hide");
                document.querySelector("#ldSubHeadingBarDisplay2").classList.remove("s2hide");
                document.querySelector("#ldSubHeadingDisplay1").classList.add("s2hide");
                document.querySelector("#ldSubHeadingDisplay2").classList.remove("s2hide");
            });
        }

        const learningAndDevelopmentFullText = document.getElementById('ldSubHeadingDisplay2');
        if (learningAndDevelopmentFullText) {
            learningAndDevelopmentFullText.addEventListener('mouseleave', () => {
                document.querySelector("#ldSubHeadingBarDisplay1").classList.remove("s2hide");
                document.querySelector("#ldSubHeadingBarDisplay2").classList.add("s2hide");
                document.querySelector("#ldSubHeadingDisplay1").classList.remove("s2hide");
                document.querySelector("#ldSubHeadingDisplay2").classList.add("s2hide");
            });
        }

        const performancesText = document.getElementById('prefSubHeadingDisplay1');
        if (performancesText) {
            performancesText.addEventListener('mouseenter', () => {
                document.querySelector("#prefSubHeadingBarDisplay1").classList.add("s2hide");
                document.querySelector("#prefSubHeadingBarDisplay2").classList.remove("s2hide");
                document.querySelector("#prefSubHeadingDisplay1").classList.add("s2hide");
                document.querySelector("#prefSubHeadingDisplay2").classList.remove("s2hide");
            });
        }

        const performancesFullText = document.getElementById('prefSubHeadingDisplay2');
        if (performancesFullText) {
            performancesFullText.addEventListener('mouseleave', () => {
                document.querySelector("#prefSubHeadingBarDisplay1").classList.remove("s2hide");
                document.querySelector("#prefSubHeadingBarDisplay2").classList.add("s2hide");
                document.querySelector("#prefSubHeadingDisplay1").classList.remove("s2hide");
                document.querySelector("#prefSubHeadingDisplay2").classList.add("s2hide");
            });
        }


        return () => {
            if (learningAndDevelopment) {
                learningAndDevelopment.removeEventListener('click', () => {
                });
            }
            if (performances) {
                performances.removeEventListener('click', () => {
                });
            }
            if (brandCommunication) {
                brandCommunication.removeEventListener('click', () => {
                });
            }
            if (brandCommunicationText) {
                brandCommunicationText.removeEventListener('mouseenter', () => {
                });
            }
            if (learningAndDevelopmentText) {
                learningAndDevelopmentText.removeEventListener('mouseenter', () => {
                });
            }
            if (performancesText) {
                performancesText.removeEventListener('mouseenter', () => {
                });
            }
            if (brandCommunicationFullText) {
                brandCommunicationFullText.removeEventListener('mouseleave', () => {
                });
            }
            if (learningAndDevelopmentFullText) {
                learningAndDevelopmentFullText.removeEventListener('mouseleave', () => {
                });
            }
            if (performancesFullText) {
                performancesFullText.removeEventListener('mouseleave', () => {
                });
            }
        }
    }, [])
    return (
        <Grid className='section2outline'>
            <Section2SVG className="background-svg2" />
            {/* <Grid container className='textContent' spacing={2}>
                <Grid item xs={2} className='innerCOntainer' style={{paddingLeft:"0px"}}>
                    <Typography variant="body1" className='section2Header' color="secondary">
                        Brand Communications
                    </Typography>
                    <Typography variant="p" gutterBottom className='section2Para' color="primary">
                        Complete partnerships from strategy <br />
                        to campaign planning to creative;
                    </Typography>
                </Grid>
                <Grid item xs={2} className='innerCOntainer' style={{paddingLeft:"55px"}}>
                    <Typography variant="body1" className='section2Header' color="secondary">
                        Learning & Development
                    </Typography>
                    <Typography variant="p" gutterBottom className='section2Para' color="primary">
                        Workshops & customized training sessions<br />
                        in business creativity, communication skills,
                    </Typography>
                </Grid>
                <Grid item xs={2} className='innerCOntainer' style={{paddingLeft:"105px"}}>
                    <Typography variant="body1" className='section2Header' color="secondary">
                        Performances
                    </Typography>
                    <Typography variant="p" gutterBottom className='section2Para' color="primary">
                        For entertainment, corporate messaging <br />
                        and training, through storytelling, music
                    </Typography>
                </Grid>
            </Grid> */}
        </Grid>
    )
}